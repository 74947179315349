.invite-container {
  display: flex;
  flex: 1;
  padding: 0px 6px 0px 14px;
  align-items: center;
  gap: 16px;
  border-radius: 0.1875rem;
  border: 1px solid #e2e2ea;
  background: #fff;
  // width: 60%;

  .email-input {
    flex: 1;
    padding: 8px;
    border: none;
    border-radius: 8px;
    background: #fff;
    color: rgba(22, 22, 30, 0.84);
    font-size: 12px;
    font-style: normal;
    font-weight: 500;

    &:focus {
      outline: none;
    }
    &::placeholder {
      color: rgba(22, 22, 30, 0.32);
    }
  }

  svg {
    cursor: pointer;
    transition: all 0.2s ease;
    
    &:hover {
      opacity: 0.8;
    }
  }
}

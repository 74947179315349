.custom-button-new {
  font-family: Poppins;
  display: inline-flex;
  align-items: center;
  justify-content: center;
  padding: 5px 12px;
  font-size: 12px;
  font-weight: 500;
  border: 1px solid #d9d9db;
  background: transparent;
  color: black;
  cursor: pointer;
  border-radius: 0.25rem;
  transition: all 0.3s, color 0.3s;
  text-wrap: nowrap;

  path {
    transition: stroke 0.3s ease;
  }

  &:hover {
    path {
      stroke: #46abff;
    }
    border-color: #46abff;
    color: #46abff;
    stroke: #46abff;
  }

  &:hover path {
    stroke: #46abff;
  }

  &:active {
    border-color: #64b5f6;
    color: #46abff;
  }
}

.disabled-secondary-button {
  border-color: #bebebe;
  color: #929292;
  cursor: not-allowed;

  &:hover {
    path {
      stroke: #929292;
    }
    border-color: #929292;
    color: #929292;
  }

  &:active {
    border-color: #bebebe;
    color: #929292;
  }
}

.small-secondary-button {
  padding: 5px 12px;
  font-size: 12px;
}

.medium-secondary-button {
  padding: 8px 16px;
  font-size: 14px;
}

.large-secondary-button {
  padding: 10px 24px;
  font-size: 14px;
}

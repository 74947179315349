.custom-button-default {
  // height: 28px;
  font-family: Poppins;
  display: flex;
  align-items: center;
  justify-content: center;
  padding: 0.38rem 0.75rem;
  border: 1px solid #1f93ef;
  background: #1f93ef;
  color: white;
  cursor: pointer;
  border-radius: 3px;
  transition: background-color 0.3s, color 0.3s;
  font-size: 0.75rem;
  font-weight: 500;

  &:hover {
    background-color: #46abff;
  }

  &:active {
    background-color: #64b5f6;
  }
}

.custom-button-disable {
  display: flex;
  align-items: center;
  justify-content: center;
  padding: 0.38rem 0.75rem;
  border: 1px solid #ebf6ff;
  background-color: #ebf6ff;
  color: rgba(22, 22, 30, 0.35);
  cursor: not-allowed;
  border-radius: 3px;
  // font-size: 0.75rem;
  font-weight: 400;
  // height: 2.5rem;
}

.small-default-button{
  padding: 5px 12px;
  font-size: 12px;
}

.medium-default-button{
  padding: 8px 16px;
  font-size: 14px;

}

.large-default-button{
  padding: 10px 24px;
  font-size: 14px;
}
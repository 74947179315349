@import url("https://fonts.googleapis.com/css2?family=Poppins:wght@100;200;300;400;500;600;700;800;900&display=swap");

.main-header-heading {
  font-size: 24px;
  font-weight: 600;
  margin-bottom: 20px !important;
}

.form-select option.text-capitalize {
  text-transform: initial;
}

.header-container {
  padding: 16px 24px;
  align-items: center;
  display: flex;
  justify-content: space-between;
  top: 0;
  background: transparent;
  width: 100%;
  background: inherit;
  position: sticky;
  z-index: 2;
  border-bottom: 1px solid rgb(203 203 203);
}

input#email {
  font-family: "Poppins", sans-serif !important;

  &:-webkit-autofill::first-line,
  &:-webkit-autofill,
  &:-webkit-autofill:hover,
  &:-webkit-autofill:focus,
  &:-webkit-autofill:active {
    font-family: "Poppins", sans-serif !important;
  }
}

.kb-wrapper {
  font-family: "Poppins", sans-serif;
  .rightSideContainer {
    background-color: #f2f3f7;
    min-height: 100vh;
    overflow-y: auto;
    width: 100%;
    display: flex;
    flex-direction: column;
    position: relative;
  }
}

.loaderContainer {
  width: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  height: 100%;
}

.input-error {
  border: 1px solid #ef1f1f !important;
}

.btn-info {
  background: #64b5f6 !important;
  color: white !important;
  border-color: #64b5f6 !important;

  &:hover {
    background: #1a8eeb !important;
  }
}

.btn-transparent {
  background: white !important;
  color: #16161ee0 !important;
  border-color: #c6cbd9 !important;

  &:hover {
    background: #c6cbd9 !important;
  }
}

.btn-outline-info {
  border-color: #1a8eeb !important;
  color: #1a8eeb !important;
}

.stickyTableCell {
  position: sticky;
  background: white;
  right: 0;
  text-align: -webkit-center !important;
  z-index: 9;
}

.stickyTableCellHeader {
  position: sticky;
  right: 0;
  background: white;
  font-weight: 700 !important;
}

.stickyTableCellHeader2 {
  position: sticky;
  right: 71px;
  background: white;
  font-weight: 700 !important;
}

.stickyTableCell2 {
  position: sticky;
  background: white;
  right: 71px;
  z-index: 9;
}

.measureTableHeader {
  padding: 24px 0px 24px 0px;
}

.mainDashContainer {
  display: flex;
  overflow: hidden;
  height: 100vh;
}

.ps-search-bar.MuiTextField-root > label {
  top: -6px;
}

.mt-30 {
  margin-top: 30px;
}

.mt-20 {
  margin-top: 20px;
}

.mt-15 {
  margin-top: 15px;
}

.me-10 {
  margin-right: 10px;
}

.mw-175p {
  min-width: 175px;
}

.w-185p {
  width: 185px;
}

.ms-10 {
  margin-left: 10px;
}

.w-20 {
  width: 20%;
}

.w-30 {
  width: 30%;
}

.w-40 {
  width: 40%;
}

.w-70 {
  width: 70%;
}

.h-85 {
  height: 85%;
}

.color-red {
  color: red;
}

.error-text-input {
  color: #ff535b;
  font-size: 13px;
  font-weight: 400;
}

.cursor-disabled {
  cursor: not-allowed;
}

.cursor-pointer {
  cursor: pointer;
}

.btn {
  padding: 8px 24px 8px 24px !important;
  width: 100%;
  text-wrap: nowrap !important;
  min-width: 135px;
}

.btn-small {
  box-shadow: none;
  border: none;
  height: 24px;
  font-size: 12px;
  font-weight: 500;
  border-radius: 8px;
  cursor: pointer;
}

.btn-success {
  background: #1f93ef !important;
  color: white !important;
  border-color: #1f93ef !important;

  &:hover {
    background: #096bbb !important;
  }
}

.btn-danger {
  background: #ff535b !important;
  color: white !important;
  border-color: #ff535b !important;

  &:hover {
    background: #c7595e !important;
  }
}

.btn-outline-dark {
  color: #16161e !important;
  border-color: #c6cbd9 !important;

  &:hover {
    background: white !important;
    color: #64b5f6 !important;
    border-color: #64b5f6 !important;
  }
}

.btn-approved-disabled {
  background-color: #05e018 !important;
  border-color: #5aff67 !important;
  color: #ffffff !important;
}

.btn-approved {
  background-color: #23c118 !important;
  border-color: #23c118 !important;
  &:hover {
    background-color: #11a007 !important;
  }
  color: #ffffff !important;
}

.btn-outline-success {
  border-color: #1f93ef !important;
  color: #1f93ef !important;
  &:hover {
    color: #0f72c4 !important;
    background-color: #e3f2fd !important;
    img {
      filter: brightness(0) saturate(100%) invert(99%) sepia(1%) saturate(544%) hue-rotate(273deg) brightness(113%)
        contrast(100%);
    }
  }
}

.btn-outline-danger {
  border-color: red !important;
  color: red !important;
  &:hover {
    color: red !important;
    background-color: rgb(255, 224, 224) !important;
    img {
      filter: brightness(0) saturate(100%) invert(99%) sepia(1%) saturate(544%) hue-rotate(273deg) brightness(113%)
        contrast(100%);
    }
  }
}

.text-wrapper-bold {
  font-weight: bold;
}

.ant-select-item-option {
  font-family: "Poppins", sans-serif;
  text-align: center;
}

.ant-select-item-option-content {
  text-align: left !important;
}

.ant-select-dropdown {
  z-index: 100000;
}

.ant-input::placeholder {
  color: rgba(0, 0, 0, 0.35) !important;
  font-size: 0.75rem !important;
}

.ant-select-selection-placeholder {
  z-index: 1000;
  color: rgba(0, 0, 0, 0.35) !important;
  font-size: 0.75rem;
}

.ant-select-item-option-selected {
  background-color: #ebf6ff !important;
  color: black;
}

.ant-select-item-option-active.ant-select-item-option-selected:not(.ant-select-item-option-disabled) {
  // border: 1px solid #22b60e;
  background-color: #ebf6ff;
  color: black;
}

.ant-select-selector {
  font-family: "Poppins" !important;
  border-radius: 0.25rem !important;
}

.clickable-text {
  color: #157cd0;
  text-decoration: underline;
  cursor: pointer;
}

.w-80p {
  width: 80px;
}
.w-170p {
  width: 170px;
}

.h-150p {
  height: 150px;
}

.h-500p {
  height: 500px;
}

.h-10rems {
  height: 10rem;
}

.ant-picker-dropdown {
  z-index: 9999 !important;
}
.btn-disabled-solid {
  background-color: #e2e2ea !important;
  color: #9a9aaf !important;
  border-color: #e2e2ea !important;
}

.page-header-text {
  font-size: 20px;
  font-weight: bold;
}

.w-100vh {
  width: 100vh;
}

.h-100vh {
  height: 100vh;
}

.light-border-bottom {
  border-bottom: 1px solid #c6cbd9;
}

.error-red-band {
  background-color: #f44336;
  color: white;
  padding: 5px 13px;
  border-radius: 8px;
  margin-bottom: 10px;
}

.div-loader {
  position: relative;
  overflow: hidden;
  border-radius: 100%;
}

.div-loader:before {
  content: "";
  background-image: conic-gradient(#04b0ee 20deg, transparent 120deg);
  height: 150%;
  width: 150%;
  position: absolute;
  left: -25%;
  top: -25%;
  animation: rotate 2s infinite linear;
}
@keyframes rotate {
  100% {
    transform: rotate(-360deg);
  }
}
.div-loader:after {
  content: "";
  height: 90%;
  width: 90%;
  position: absolute;
  background-color: white;
  border-radius: 100%;
  top: 3%;
  left: 3%;
  color: #04b0ee;
  display: grid;
  place-items: center;
  font-size: 20px;
  letter-spacing: 6px;
  z-index: 0;
}

.ant-checkbox-disabled + span {
  color: rgba(0, 0, 0, 0.6);
}

.main-page-title {
  font-size: 20px;
  font-weight: 500 !important;
}

.heading-h6 {
  color: #16161e;
  font-size: 20px;
  font-style: normal;
  font-weight: 500;
}

.subtitle-1 {
  color: #16161e;
  font-size: 16px;
  font-style: normal;
  font-weight: 500;
}
.subtitle-2 {
  color: #16161e;
  font-size: 14px;
  font-style: normal;
  font-weight: 500;
  line-height: 22px;
}

.subtitle-2-overdue {
  color: #ff535b;
  font-size: 14px;
  font-style: normal;
  font-weight: 500;
}

.caption-1-Regular {
  color: rgba(22, 22, 30, 0.7);
  font-size: 12px;
  font-style: normal;
  font-weight: 400;
}

.caption-1-Regular-overdue {
  color: #ff4d4f;
  font-size: 12px;
  font-style: normal;
  font-weight: 400;
}

.caption-2-Regular {
  color: rgba(22, 22, 30, 0.7);
  font-size: 10px;
  font-style: normal;
  font-weight: 400;
}

.card-layout {
  display: flex;
  // padding: 12px 14px 10px 12px;
  border-radius: 8px;
  border: 0.8px solid var(--Neutral-30, #e2e2ea);
  background: var(--White-100, #fff);
}

.multi-toggle-wrapper {
  display: flex;
  padding: 2px;
  align-items: center;
  gap: 2px;
  border-radius: 6px;
  background: var(--Neutral-10, #f2f3f7);
  width: fit-content;
}
.bg-trans {
  background-color: transparent !important;
}
.body-2 {
  color: #16161e;
  font-size: 14px;
  font-style: normal;
  font-weight: 500;
  margin-bottom: 0;
}

.body-1-Regular {
  color: rgba(22, 22, 30, 0.7);
  font-size: 16px;
  font-style: normal;
  font-weight: 400;
}
.body-2-Regular {
  color: rgba(22, 22, 30, 0.7);
  font-size: 14px;
  font-style: normal;
  font-weight: 400;
}

p {
  margin: 0;
  padding: 0;
}

.caption-1-Emphasized {
  color: #16161e;
  text-align: center;
  font-size: 12px;
  font-style: normal;
  font-weight: 500;
}

.ant-radio-wrapper .ant-radio-checked .ant-radio-inner {
  border-color: #64b5f6 !important;
  background-color: white !important;
}

.ant-radio-wrapper .ant-radio-checked .ant-radio-inner::after {
  background-color: #64b5f6 !important;
}

.ant-steps-item-title {
  font-size: 13px !important;
  font-weight: 500 !important;
}

.ant-steps-item-description {
  font-size: 12px !important;
  font-weight: 400 !important;
  max-width: 170px !important;
}

.ant-steps-item-active {
  .ant-steps-item-icon {
    background-color: #64b5f6 !important;
    border-color: #64b5f6 !important;
  }
}

.ant-steps-item-finish {
  .ant-steps-item-title::after {
    background-color: #64b5f6 !important;
  }
}

.ant-steps-item-finish {
  .ant-steps-item-icon {
    background-color: #38cb24 !important;
    border-color: #38cb24 !important;

    .ant-steps-icon {
      color: white !important;
    }
  }
}

.ant-switch-checked {
  background-color: #38cb24 !important;
}

// .ant-switch-handle {
//   background-color: white;
// }

.custom-input-2 {
  border-radius: 0.25rem !important;
  font-size: 0.75rem;
  padding: 0.75rem !important;
}

.custom-input-2::placeholder {
  color: rgba(22, 22, 30, 0.35) !important;
  font-size: 0.75rem;
}

.ant-select-selection-item {
  font-size: 0.75rem;
}

.ant-picker-large .ant-picker-input > input {
  font-size: 0.75rem !important;
  line-height: 1.5;
}

.custom-select-antd .ant-select-selection-item {
  font-size: unset !important;
}

.custom-select-antd .ant-select-selector {
  font-size: unset !important;
}

.heading-text {
  color: #16161e;
  font-size: 1rem;
  font-style: normal;
  font-weight: 500;
  font-family: Poppins;
}
.sub-heading-text {
  color: rgba(22, 22, 30, 0.64);
  font-size: 0.875rem;
  font-style: normal;
  font-weight: 400;
  font-family: Poppins;
}

.custom-new-table {
  // padding: unset !important;
  th {
    color: #16161e;
    font-size: 12px;
    font-style: normal;
    font-weight: 500;
    border-top: 1px solid #e2e2ea;
  }
  td {
    color: rgba(22, 22, 30, 0.7);
    font-size: 12px;
    font-style: normal;
    font-weight: 500;
    text-align: start;
    border-bottom: 1px solid #e2e2ea;
  }

  .sub-text {
    color: rgba(22, 22, 30, 0.68);
    font-size: 12px;
    font-style: normal;
    font-weight: 400;
    white-space: nowrap;
  }
}

.custom-material-table-container {
  height: 100%;
  overflow: auto;
  position: relative;
  margin-top: 1.5rem;

  .custom-material-table {
    flex: 1;
    overflow: visible;
    position: relative;

    .MuiTableContainer-root {
      width: 100%;
      overflow-x: auto;
      margin-bottom: 1rem;
      padding-right: 0;
      max-height: 100%;

      &::-webkit-scrollbar {
        background-color: transparent;
        height: 8px;
        scrollbar-gutter: stable;
        width: 5px;
      }
      &:hover::-webkit-scrollbar {
        height: 8px;
        scrollbar-gutter: stable;
        width: 5px;
      }
      &:hover::-webkit-scrollbar-thumb {
        background-color: #00000033;
        scrollbar-gutter: stable;
      }
    }

    th,
    td {
      &:first-child {
        left: 0;
        width: 50px;
        padding-right: 0 !important;
      }

      // &:nth-child(2) {
      //   width: 70px;
      // }
    }

    th {
      color: #16161e;
      font-size: 0.75rem;
      font-style: normal;
      text-align: start;
      font-weight: 500;
      padding-top: 0.75rem !important;
      padding-bottom: 0.75rem !important;
      background: white !important;
      border-top: 1px solid #e2e2ea;
      white-space: nowrap;
      overflow: hidden;
      text-overflow: ellipsis;
    }

    td {
      color: rgba(22, 22, 30, 0.7);
      font-size: 0.75rem;
      font-style: normal;
      font-weight: 500;
      text-align: start;
      border-bottom: 1px solid #e2e2ea;
      padding-top: 0.75rem !important;
      padding-bottom: 0.75rem !important;
      max-width: 17rem;
      white-space: nowrap;
      overflow: hidden;
      text-overflow: ellipsis;

      .d-flex {
        opacity: 0;
        transition: opacity 0.2s ease-in-out;
      }
    }

    .MuiCheckbox-root.Mui-disabled {
      opacity: 0.5;
      cursor: not-allowed !important;
      pointer-events: auto !important;
    }

    .sub-text {
      color: rgba(22, 22, 30, 0.68);
      font-size: 0.75rem;
      font-style: normal;
      font-weight: 400;
      white-space: nowrap;
    }

    tr {
      &:hover {
        background-color: #f9f9f9;
        cursor: pointer;
        .no-display {
          opacity: 1;
        }

        .supplier-id-container {
          background-color: rgba(255, 255, 255, 0.85);
        }

        .right-arrow-icon {
          path {
            stroke: #0066ff;
          }
        }
      }

      &.Mui-selected {
        background-color: #f9f9f9 !important;

        td {
          background-color: #f9f9f9 !important;
        }

        &:hover {
          background-color: #f9f9f9 !important;

          td {
            background-color: #f9f9f9 !important;
          }
        }
      }
    }

    .MuiCheckbox-root {
      padding: 0;
      color: rgba(0, 0, 0, 0.2);

      &.Mui-checked {
        color: #1f93ef;
      }

      &.MuiCheckbox-indeterminate {
        color: #1f93ef;
      }
    }

    .file-name-cell {
      display: flex;
      align-items: center;
      gap: 0.5rem;

      .file-icon {
        display: flex;
        align-items: center;
        justify-content: center;

        img {
          object-fit: contain;
        }
      }
    }

    .status-chip {
      display: flex;
      padding: 0.0625rem 0.5rem 0.0625rem 0.0625rem;
      align-items: center;
      border-radius: 0.1875rem;

      .status-text {
        color: rgba(22, 22, 30, 0.7);
        font-family: Poppins;
        font-size: 0.75rem;
        font-style: normal;
        font-weight: 500;
        line-height: 1rem;
      }
    }
  }
}

.heading-text-small {
  font-size: 0.8125rem;
  font-weight: 500;
}

.blue-text-small {
  color: var(--Blue-50, #1f93ef);
  font-size: 0.75rem;
  font-weight: 500;
  cursor: pointer;
}

.blue-text-medium {
  color: var(--Blue-50, #1f93ef);
  font-size: 0.875rem;
  font-weight: 500;
  cursor: pointer;
}

.red-circle-container {
  width: 2rem;
  height: 2rem;
  border-radius: 50%;
  background-color: #ffeeef;
  display: flex;
  justify-content: center;
  align-items: center;
}

.highcharts-xaxis-labels > span {
  overflow: hidden !important;
  white-space: nowrap !important;
  text-overflow: ellipsis !important;
  max-width: 75px; /* Set desired width */
}

.blue-square-background {
  display: flex;
  padding: 5px 6px;
  justify-content: center;
  align-items: center;
  border-radius: 3px;
  background: #ebf6ff;
  cursor: pointer;
}

//antd selector style
.gray-select-global {
  .ant-select-selector {
    border-radius: 0.1875rem !important;
    background: #f6f9f9 !important;
    // border-color: 1px solid var(--Neutral-30, #e2e2ea);

    &:hover {
      border-color: 1px solid var(--Neutral-30, #b9b9bc);
    }

    .ant-select-selection-placeholder {
      color: rgba(22, 22, 30, 0.7) !important;
    }

    .ant-select-selection-item {
      color: rgba(22, 22, 30, 0.7) !important;
    }

    .ant-select-item-option-selected {
      border: unset !important;
    }
  }

  .ant-select-arrow {
    color: rgba(22, 22, 30, 0.7) !important;
  }
}

.disabled-select-global {
  .ant-select-selector {
    border-radius: 0.25rem;
    border: 1px solid var(--Neutral-30, #e2e2ea) !important;
    background: var(--White-85, rgba(255, 255, 255, 0.85)) !important;

    .ant-select-selection-placeholder {
      color: rgba(0, 0, 0, 0.35) !important;
    }
  }
}

.form-input {
  display: block;
  width: 100%;
  padding: 0.375rem 2.25rem 0.375rem 0.75rem;
  font-size: 1rem;
  font-weight: 400;
  line-height: 1.5;
  color: #212529;
  appearance: none;
  background-color: #fff;
  background-repeat: no-repeat;
  background-position: right 0.75rem center;
  background-size: 16px 12px;
  border: 1px solid #dee2e6;
  border-radius: 0.375rem;
  transition: border-color 0.15s ease-in-out, box-shadow 0.15s ease-in-out;
}

.form-input:focus {
  border-color: #86b7fe;
  outline: 0;
  box-shadow: 0 0 0 0.25rem rgba(13, 110, 253, 0.25);
}

.rc-virtual-list-scrollbar-thumb {
  width: 2px !important;
}

.text-bold-gobal {
  font-size: 16px;
  font-style: normal;
  font-weight: 500;
}

.text-subtitle {
  font-size: 14px;
  font-style: normal;
  font-weight: 400;
  color: rgba(22, 22, 30, 0.7);
}

.text-subtitle-bold {
  font-size: 14px;
  font-style: normal;
  font-weight: 500;
}

.text-small-gray-bold {
  font-size: 12px !important;
  font-style: normal;
  font-weight: 500 !important;
  color: rgba(22, 22, 30, 0.7);
}

.text-small-gray {
  font-size: 12px;
  font-style: normal;
  font-weight: 400;
  color: rgba(22, 22, 30, 0.7);
}

.by-3-grid {
  display: grid;
  grid-template-columns: repeat(3, 1fr); /* 3 equal columns */
  column-gap: 16px;
  row-gap: 24px;
  width: 100%;
}

.by-2-grid {
  display: grid;
  grid-template-columns: repeat(2, 1fr); /* 3 equal columns */
  column-gap: 16px;
  row-gap: 24px;
  width: 100%;
}

.bottom-footer-fixed {
  padding: 24px;
  bottom: 0;
  background: white;
  display: flex;
  justify-content: space-between;
  align-items: center;
  width: 100%;
  border-top: 1px solid #e2e2ea;
}

#input-file-upload {
  display: none;
}

#drag-file-element {
  position: absolute;
  width: 100%;
  height: 100%;
  border-radius: 1rem;
  top: 0px;
  right: 0px;
  bottom: 0px;
  left: 0px;
}

// #label-file-upload {
//   height: 100%;
//   display: flex;
//   align-items: center;
//   justify-content: center;
//   border-width: 2px;
//   border-radius: 1rem;
//   border-style: dashed;
//   border-color: #cbd5e1;
//   background-color: #f8fafc;
// }

#label-file-upload.drag-active {
  background-color: #f8fafc;
}

.error-global-message {
  display: flex;
  height: 36px;
  padding: 3px 8px;
  align-items: center;
  gap: 6px;
  align-self: stretch;
  border-radius: 3px;
  border: 0.5px solid #ff535b;
  background: rgba(255, 83, 91, 0.04);
  font-size: 12px;
  font-style: normal;
  font-weight: 500;
  color: rgba(0, 0, 0, 0.6);
}

.multi-error-container {
  display: flex;
  padding: 12px 16px;
  align-items: center;
  gap: 6px;
  align-self: stretch;
  border: 1px solid #eaebf1;
  background: #fff;
}

.gray-select-peer {
  .ant-select-selector {
    border-radius: 0.1875rem !important;
    background: #f6f9f9 !important;
    // border-color: 1px solid var(--Neutral-30, #e2e2ea);

    &:hover {
      border-color: 1px solid var(--Neutral-30, #b9b9bc);
    }

    .ant-select-selection-placeholder {
      color: rgba(22, 22, 30, 0.7) !important;
      // font-weight: 500 !important;
    }

    .ant-select-selection-item {
      color: #16161e !important;
      // font-weight: 500 !important;
    }

    .ant-select-item-option-selected {
      border: unset !important;
    }
  }

  .ant-select-arrow {
    color: rgba(22, 22, 30, 0.7) !important;
  }
}

.gray-datepicker {
  font-size: 0.75rem !important;
  height: 40px !important;
  // font-weight: 500 !important;
  border: 1px solid #eaebf1 !important;
  background: #f9f9f9 !important;
  border-radius: 3px;

  .ant-picker-input > input {
    background-color: #f9f9f9 !important;
    font-weight: 500 !important;
    color: #16161e;
  }
  .ant-picker-input > input::placeholder {
    color: rgba(22, 22, 30, 0.7) !important;
  }
}

.ant-picker-input > input {
  // font-weight: 500 !important;
  color: #16161e;
}

.ant-select-selector {
  // font-weight: 500 !important;
  color: #16161e;
}

.ant-select-selection-placeholder {
  // font-weight: 500 !important;
  color: #16161e;
}

.ant-picker-input > input::placeholder {
  // font-weight: 500 !important;
  color: rgba(22, 22, 30, 0.32) !important;
}

.over-flow-scroll {  
  &::-webkit-scrollbar {
    background-color: transparent;
    height: 6px;
    width: 6px;
    scrollbar-gutter: stable;
    margin-left: 9px;

    padding-left: 9px;
  }

  &:hover::-webkit-scrollbar {
    height: 6px;
    width: 6px;
    scrollbar-gutter: stable;
    margin-left: 9px;

    padding-left: 9px;
  }
  &:hover::-webkit-scrollbar-thumb {
    background-color: #c6cbd9;
    border-radius: 8px;
    scrollbar-gutter: stable;
    margin-left: 9px;
    padding-left: 9px;
  }
}
